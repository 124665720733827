import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { TaxaAmbiental } from '../../../index';

const FormCad = ({
    handleSubmit,
    schema,
    tipo_licenca,
    setTipoLicenca,
    errors,
}) => {
    const handleChange = (event) => {
        const { name, type, checked, value, files } = event.target;
        const newValue =
            type === 'checkbox' ? checked : files ? files[0] : value;
        setTipoLicenca({
            ...tipo_licenca,
            [name]: newValue,
        });
    };
    const formatMoney = (e) => {
        let tmp = parseInt(e.target.value.replace(/[\D]+/g, ''));
        if (tmp) {
            tmp = String(tmp).replace(/([0-9]{2})$/g, ',$1');
            if (tmp.length === 1) {
                tmp = `0,0${tmp}`;
            }
            if (tmp.length === 3) {
                tmp = `0${tmp}`;
            }

            if (tmp.length > 6) {
                tmp = String(tmp).replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
            }
        } else {
            tmp = '0,00';
        }

        setTipoLicenca({
            ...tipo_licenca,
            [e.target.name]: tmp,
        });
    };

    return (
        <Form id="form_tipo_licenca" onSubmit={handleSubmit} schema={schema}>
            <div className="form-group">
                <label htmlFor="nome"> Nome </label>
                <Form.Control
                    id="nome"
                    isInvalid={!!errors.nome}
                    name="nome"
                    onChange={handleChange}
                    placeholder="Nome"
                    type="text"
                    value={tipo_licenca.nome || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.nome}
                </Form.Control.Feedback>
            </div>

            <div className="form-group">
                <label htmlFor="sigla"> Sigla </label>
                <Form.Control
                    id="sigla"
                    isInvalid={!!errors.sigla}
                    name="sigla"
                    onChange={handleChange}
                    placeholder="Sigla da tipo_licenca"
                    type="text"
                    value={tipo_licenca.sigla || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.sigla}
                </Form.Control.Feedback>
            </div>

            <div className="row">
                <div className="col-sm-12 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="sigla"> Quantidade de Expiração </label>
                        <Form.Control
                            id="expiration_quantity"
                            isInvalid={!!errors.expiration_quantity}
                            name="expiration_quantity"
                            onChange={handleChange}
                            placeholder="Quantidade de Expiração da Licença Ambiental"
                            type="text"
                            value={tipo_licenca.expiration_quantity || ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.expiration_quantity}
                        </Form.Control.Feedback>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="sigla"> Unidade de Expiração </label>
                        <Form.Control
                            id="expiration_unit"
                            as="select"
                            isInvalid={!!errors.expiration_unit}
                            name="expiration_unit"
                            onChange={handleChange}
                            placeholder="expiration_unit da Licença Ambiental"
                            value={tipo_licenca.expiration_unit || ''}
                        >
                            <option value="" disabled>
                                Selecione a unidade de expiração
                            </option>
                            <option value="days"> Dia(s)</option>
                            <option value="months">Mes(es)</option>
                            <option value="years">Ano(s)</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {errors.expiration_unit}
                        </Form.Control.Feedback>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="sigla"> Validade </label>
                <Form.Control
                    id="validade"
                    isInvalid={!!errors.validade}
                    name="validade"
                    onChange={handleChange}
                    placeholder="Validade da Licença Ambiental"
                    type="text"
                    value={tipo_licenca.validade || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.validade}
                </Form.Control.Feedback>
            </div>
            <hr />
            <div className="form-group">
                {/* <Form.Check
                    id="isencao_taxa_ambiental"
                    isInvalid={!!errors.isencao_taxa_ambiental}
                    name="isencao_taxa_ambiental"
                    onChange={handleChange}
                    type="checkbox"
                    label="Isenção de Taxa Ambiental"
                    checked={!!tipo_licenca.isencao_taxa_ambiental}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.isencao_taxa_ambiental}
                </Form.Control.Feedback> */}


                <Button
                    variant={
                        tipo_licenca.isencao_taxa_ambiental
                        ? 'danger'
                        : 'success'
                    }
                    onClick={() =>
                        setTipoLicenca({
                            ...tipo_licenca,
                            isencao_taxa_ambiental:
                                !tipo_licenca.isencao_taxa_ambiental,
                        })
                    }
                >
                    {tipo_licenca.isencao_taxa_ambiental
                        ? 'Desabilitar Isenção de Taxa Ambiental'
                        : 'Habilitar Isenção de Taxa Ambiental'}
                </Button>
            </div>
            <div className="form-group">
                {/* <Form.Check
                    id="has_vistoria_tecnica"
                    isInvalid={!!errors.has_vistoria_tecnica}
                    name="has_vistoria_tecnica"
                    onChange={handleChange}
                    type="checkbox"
                    label="Habilitar vistoria técnica"
                    checked={!!tipo_licenca.has_vistoria_tecnica}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.has_vistoria_tecnica}
                </Form.Control.Feedback> */}

                <Button
                    variant={
                        tipo_licenca.has_vistoria_tecnica ? 'danger' : 'success'
                    }
                    onClick={() =>
                        setTipoLicenca({
                            ...tipo_licenca,
                            has_vistoria_tecnica:
                                !tipo_licenca.has_vistoria_tecnica,
                        })
                    }
                >
                    {tipo_licenca.has_vistoria_tecnica
                        ? 'Desabilitar Vistoria Técnica'
                        : 'Habilitar Vistoria Técnica'}
                </Button>
            </div>

            <div className="form-group">
                <label htmlFor="taxa_requerimento">
                    {' '}
                    Taxa de Requerimento de Publicação{' '}
                </label>
                <Form.Control
                    id="taxa_requerimento"
                    isInvalid={!!errors.taxa_requerimento}
                    name="taxa_requerimento"
                    onChange={formatMoney}
                    maxLength={10}
                    type="text"
                    placeholder="Taxa de Requerimento de Publicação"
                    value={tipo_licenca.taxa_requerimento || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.taxa_requerimento}
                </Form.Control.Feedback>
            </div>

            <div className="form-group">
                <label htmlFor="taxa_recebimento">
                    {' '}
                    Taxa de Recebimento de Publicação{' '}
                </label>
                <Form.Control
                    id="taxa_recebimento"
                    isInvalid={!!errors.taxa_recebimento}
                    name="taxa_recebimento"
                    onChange={formatMoney}
                    maxLength={10}
                    placeholder="Taxa de Recebimento de Publicação"
                    type="text"
                    value={tipo_licenca.taxa_recebimento || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.taxa_recebimento}
                </Form.Control.Feedback>
            </div>

            <div className="form-group">
                <label htmlFor="qtd_tec_para_analise">
                    {' '}
                    Qtd. de técnicos para analise{' '}
                </label>
                <Form.Control
                    id="qtd_tec_para_analise"
                    isInvalid={!!errors.qtd_tec_para_analise}
                    name="qtd_tec_para_analise"
                    onChange={handleChange}
                    placeholder="Qtd. de técnicos para analise"
                    type="number"
                    value={tipo_licenca.qtd_tec_para_analise || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.qtd_tec_para_analise}
                </Form.Control.Feedback>
            </div>

            <div className="form-group">
                <label htmlFor="qtd_hora_tec_necessaria">
                    {' '}
                    Qtd. de horas necessárias{' '}
                </label>
                <Form.Control
                    id="qtd_hora_tec_necessaria"
                    isInvalid={!!errors.qtd_hora_tec_necessaria}
                    name="qtd_hora_tec_necessaria"
                    onChange={handleChange}
                    placeholder="Qtd. de horas necessárias"
                    type="number"
                    value={tipo_licenca.qtd_hora_tec_necessaria || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.qtd_hora_tec_necessaria}
                </Form.Control.Feedback>
            </div>
            <div className="form-group">
                <label htmlFor="valor_hora_tec">
                    {' '}
                    Valor hora técnica (R$){' '}
                </label>
                <Form.Control
                    id="valor_hora_tec"
                    isInvalid={!!errors.valor_hora_tec}
                    maxLength={10}
                    name="valor_hora_tec"
                    onChange={formatMoney}
                    placeholder="Valor hora técnica"
                    type="text"
                    value={tipo_licenca.valor_hora_tec || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.valor_hora_tec}
                </Form.Control.Feedback>
            </div>

            <div className="form-group">
                <label htmlFor="qtd_diarias"> Qtd. de diárias </label>
                <Form.Control
                    id="qtd_diarias"
                    isInvalid={!!errors.qtd_diarias}
                    name="qtd_diarias"
                    onChange={handleChange}
                    placeholder="Qtd. de diárias"
                    type="number"
                    value={tipo_licenca.qtd_diarias || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.qtd_diarias}
                </Form.Control.Feedback>
            </div>
            <div className="form-group">
                <label htmlFor="valor_diaria"> Valor de diária (R$)</label>
                <Form.Control
                    id="valor_diaria"
                    isInvalid={!!errors.valor_diaria}
                    maxLength={10}
                    name="valor_diaria"
                    onChange={formatMoney}
                    placeholder="Valor de diária"
                    type="text"
                    value={tipo_licenca.valor_diaria || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.valor_diaria}
                </Form.Control.Feedback>
            </div>

            <div className="form-group">
                <label htmlFor="valor_combustivel_vistoria">
                    {' '}
                    Valor combustível vistoria (R$)
                </label>
                <Form.Control
                    id="valor_combustivel_vistoria"
                    isInvalid={!!errors.valor_combustivel_vistoria}
                    maxLength={10}
                    name="valor_combustivel_vistoria"
                    onChange={formatMoney}
                    placeholder="Valor combustível vistoria"
                    type="text"
                    value={tipo_licenca.valor_combustivel_vistoria || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.valor_combustivel_vistoria}
                </Form.Control.Feedback>
            </div>

            <div className="form-group">
                <label htmlFor="qtd_viagem_vistoria">
                    {' '}
                    Qtd. viagem vistoria{' '}
                </label>
                <Form.Control
                    id="qtd_viagem_vistoria"
                    isInvalid={!!errors.qtd_viagem_vistoria}
                    name="qtd_viagem_vistoria"
                    onChange={handleChange}
                    placeholder="Qtd. viagem vistoria"
                    type="number"
                    value={tipo_licenca.qtd_viagem_vistoria || ''}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.qtd_viagem_vistoria}
                </Form.Control.Feedback>
            </div>

            <div className="form-group">
                <label htmlFor="has_apia_atia"> Tem APIA e ATIA? </label>
                <Form.Control
                    as="select"
                    id="has_apia_atia"
                    isInvalid={!!errors.has_apia_atia}
                    name="has_apia_atia"
                    onChange={handleChange}
                    placeholder="Tem APIA e ATIA?"
                    value={String(Number(tipo_licenca.has_apia_atia))}
                >
                    <option value="1"> SIM</option>
                    <option value="0">NÃO</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {errors.qtd_viagem_vistoria}
                </Form.Control.Feedback>
            </div>
        </Form>
    );
};

export default FormCad;
