import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { BasicContent } from '../components';
import { Loading } from '~/components';
import formatForMask from '~/utils/formatForMask';
import {
    MovimentacaoProcesso,
    CheckList,
    // MemorialEmpreendimento,
    IndicadoresAmbientais,
    ApiaResumo,
    MensagensProcesso,
    AnaliseTecImpacto,
    AnaliseImpactoAmbiental,
} from '~/views/Process/components';
import { showProcesso } from '~/store/modules/processo/action';
import { loadIndicadoresAmbientais } from '~/store/modules/indicadorAmbiental/action';
import { loadCondicionantesProcesso } from '~/store/modules/processo/action';
import { ProgressBar } from '../../Process/List/styles';
import {
    ParecerProjeto,
    // EstudosAmbientais,
    // NotificacaoTecnicaAmbiental,
    NotaTecAmbiental,
    AgendaVisionariaTecAmb,
    RelatorioVistoria,
    CondicionantesAmbientais,
    Autorizacao,
    // AnaliseTecImpacto
} from '../../AnaliseProcessos/Show/components';

const Show = () => {
    let { processo_id } = useParams();
    const dispatch = useDispatch();
    const { processo } = useSelector(({ ProcessoReducer }) => ProcessoReducer);
    const { cond_geral, cond_especifica } = useSelector(
        ({ ProcessoReducer }) => ProcessoReducer
    );
    const { relatorio_vistoria } = useSelector(
        ({ RelatorioVistoriaReducer }) => RelatorioVistoriaReducer
    );
    const { indicador_processo } = useSelector(
        ({ IndicadorProcessoReducer }) => IndicadorProcessoReducer
    );
    const { indicadores_ambientais } = useSelector(
        ({ IndicadorAmbientalReducer }) => IndicadorAmbientalReducer
    );

    const [, setParecerIsOk] = useState(false);
    const [, setIndicadoresIsOk] = useState(false);
    const [, setDocsTecIsOk] = useState(false);
    const [, setCondicionantesIsOk] = useState(false);
    const [, setNotaTecIsOk] = useState(false);
    const [, setAgendaVistoriaIsOk] = useState(false);
    const [, setRelatorioIsOk] = useState(false);

    useEffect(() => {
        dispatch(loadCondicionantesProcesso(processo_id));
    }, [processo_id, dispatch]);
    useEffect(() => {
        dispatch(
            showProcesso(processo_id, {
                with:
                    'analise_processo,' +
                    'analise_previa,' +
                    'pessoa,' +
                    'documentos,' +
                    'estado,' +
                    'municipio,' +
                    'atividade,' +
                    'pessoa_responsavel_tecnico,' +
                    'tipo_licenca,' +
                    'status_processo,' +
                    'porte_empreendimento,' +
                    'projeto,' +
                    'vistorias.relatorio,' +
                    'user_avaliador,' +
                    'entidade,' +
                    'tramites,' +
                    'grupo_atividades',
            })
        );
    }, [dispatch, processo_id, relatorio_vistoria]);

    useEffect(() => {
        dispatch(
            loadIndicadoresAmbientais({
                where: `processo_id:${processo_id}`,
            })
        );
    }, [processo_id, dispatch, indicador_processo]);

    useEffect(() => {
        if (processo.documentos && processo.documentos?.length > 0) {
            setDocsTecIsOk(true);
        }
    }, [processo.documentos]);

    useEffect(() => {
        if (indicadores_ambientais && indicadores_ambientais.length > 0) {
            indicadores_ambientais.forEach((indicador) => {
                if (indicador.valor) {
                    setIndicadoresIsOk(true);
                }
            });
        }
    }, [indicadores_ambientais]);

    return (
        <BasicContent
            breadcrumb={{
                label: 'Analise Processo',
                to: '/analise-processo',
                active: true,
            }}
            title="Detalhes Processo."
        >
            <div className="card">
                {processo &&
                processo.protocolo &&
                processo.pessoa_responsavel_tecnico ? (
                    <>
                        <div className="card-header">
                            <h3 className="card-title">
                                PROCESSO : <strong>{processo.protocolo}</strong>
                            </h3>
                            <div className="card-tools">
                                <Link
                                    className="btn  btn-default"
                                    to="/analise-processo"
                                >
                                    <FaArrowAltCircleLeft />
                                    Voltar
                                </Link>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table table-hover table-striped table-bordered table-sm">
                                <tbody>
                                    <tr>
                                        <th>Processo</th>
                                        <td>{processo.protocolo}</td>
                                    </tr>
                                    <tr>
                                        <th>Empreendimento</th>
                                        <td>
                                            {' '}
                                            {`${processo.pessoa.nome} - ${
                                                processo.pessoa.cnpj
                                                    ? formatForMask(
                                                          processo.pessoa.cnpj,
                                                          '##.###.###/####-##'
                                                      )
                                                    : ''
                                            } ${
                                                processo.pessoa.cpf
                                                    ? formatForMask(
                                                          processo.pessoa.cpf,
                                                          '###.###.###-##'
                                                      )
                                                    : ''
                                            }`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Porte Empreendimento</th>
                                        <td>
                                            {processo.porte_empreendimento &&
                                                processo.porte_empreendimento
                                                    .nome}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Local</th>
                                        <td>
                                            {' '}
                                            {processo.municipio.nome} -{' '}
                                            {processo.estado.uf}
                                        </td>
                                    </tr>
                                    {processo.entidade?.cnae_group &&
                                    processo.grupo_atividades ? (
                                        <>
                                            <tr>
                                                <th>
                                                    Atividade Principal
                                                    Licenciada
                                                </th>
                                                <td>
                                                    {' '}
                                                    {processo.atividade?.nome}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Atividade Local Principal
                                                    Licenciada
                                                </th>
                                                <td>
                                                    {' '}
                                                    {processo.atividade &&
                                                        processo.atividade
                                                            ?.nome_local}
                                                </td>
                                            </tr>
                                            {processo.grupo_atividades.map(
                                                (grupo, index) => (
                                                    <tr key={index}>
                                                        <th>
                                                            Atividade(s)
                                                            Secundária(s)
                                                            Licenciada(s)
                                                        </th>
                                                        <td>{grupo.nome}</td>
                                                    </tr>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <tr>
                                                <th>
                                                    Nome da Atividade na Receita
                                                    Federal
                                                </th>
                                                <td>
                                                    {' '}
                                                    {processo.atividade?.nome}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Nome Local da Atividade</th>
                                                <td>
                                                    {' '}
                                                    {processo.atividade &&
                                                        processo.atividade
                                                            ?.nome_local}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    <tr>
                                        <th>Endereço da atividade</th>
                                        <td>
                                            {processo.logradouro} -{' '}
                                            {processo.bairro} -{' '}
                                            {processo.numero_endereco
                                                ? processo.numero_endereco
                                                : 'Nº não informado'}{' '}
                                            - {processo.cep} -{' '}
                                            {processo.complemento
                                                ? processo.complemento
                                                : 'Sem complemento.'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Tipo de licença</th>
                                        <td> {processo.tipo_licenca.nome}</td>
                                    </tr>
                                    <tr>
                                        <th>Técnico Consultor</th>
                                        <td>
                                            {processo.pessoa_responsavel_tecnico &&
                                                processo
                                                    .pessoa_responsavel_tecnico
                                                    .responsavel_tecnico &&
                                                processo
                                                    .pessoa_responsavel_tecnico
                                                    .responsavel_tecnico
                                                    .pessoa_tecnico_profissional &&
                                                processo
                                                    .pessoa_responsavel_tecnico
                                                    .responsavel_tecnico
                                                    .pessoa_tecnico_profissional
                                                    .nome}{' '}
                                            -{' '}
                                            {processo.pessoa_responsavel_tecnico &&
                                            processo.pessoa_responsavel_tecnico
                                                .responsavel_tecnico &&
                                            processo.pessoa_responsavel_tecnico
                                                .responsavel_tecnico
                                                .pessoa_tecnico_profissional &&
                                            processo.pessoa_responsavel_tecnico
                                                .responsavel_tecnico
                                                .pessoa_tecnico_profissional
                                                .cnpj
                                                ? formatForMask(
                                                      processo
                                                          .pessoa_responsavel_tecnico
                                                          .responsavel_tecnico
                                                          .pessoa_tecnico_profissional
                                                          .cnpj,
                                                      '##.###.###/####-##'
                                                  )
                                                : '' -
                                                  processo
                                                      .pessoa_responsavel_tecnico
                                                      .responsavel_tecnico
                                                      .pessoa_tecnico_profissional
                                                      .cpf
                                                ? formatForMask(
                                                      processo
                                                          .pessoa_responsavel_tecnico
                                                          .responsavel_tecnico
                                                          .pessoa_tecnico_profissional
                                                          .cpf,
                                                      '###.###.###-##'
                                                  )
                                                : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Endereço da Empresa</th>
                                        <td>
                                            {processo.pessoa.logradouro},
                                            {processo.pessoa.bairro} -
                                            {processo.pessoa.cep} -
                                            {processo.pessoa.cidade}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>
                                            <span className="badge badge-success">
                                                {processo.status_processo?.nome}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Progresso</th>
                                        <td className="project_progress">
                                            <div className="progress progress-sm">
                                                <ProgressBar
                                                    ariaVolumemax="100"
                                                    ariaVolumemin="0"
                                                    ariaVolumenow={
                                                        (processo.passo * 100) /
                                                        20
                                                    }
                                                    className="progress-bar bg-green"
                                                    percents={
                                                        (processo.passo * 100) /
                                                        20
                                                    }
                                                    role="progressbar"
                                                />
                                            </div>
                                            <small>
                                                {(processo.passo * 100) / 20}%
                                                completo
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
            <div className="row">
                {processo?.status_processo?.id >= 10 && (
                    <>
                        <div className="col-md-4">
                            <ParecerProjeto
                                onlyView
                                processo_id={processo.id}
                                projeto={processo.projeto}
                                setParecerIsOk={setParecerIsOk}
                            />
                        </div>
                        {/* <div className="col-md-4">
              <MemorialEmpreendimento
                processo_id={processo.id}
                tipo_licenca_id={processo.tipo_licenca.id}

              />
            </div> */}
                    </>
                )}

                {processo.tipo_licenca?.has_apia_atia && (
                    <>
                        {processo ? (
                            <div className="col-md-4">
                                <AnaliseTecImpacto
                                    analise_id={processo.analise_processo?.id}
                                    analise_previa={false}
                                    onlyView
                                    processo_id={processo.id}
                                    tipo_licenca_id={processo.tipo_licenca.id}
                                />
                            </div>
                        ) : (
                            <Loading />
                        )}
                    </>
                )}

                <div className="col-md-4">
                    <CondicionantesAmbientais
                        cond_especifica={cond_especifica}
                        cond_geral={cond_geral}
                        onlyView
                        processo_id={processo_id}
                        setCondicionantesIsOk={setCondicionantesIsOk}
                    />
                </div>

                <div className="col-md-4">
                    <Autorizacao
                        entidade_id={processo.entidade_id}
                        processo_id={processo_id}
                        status_processo_id={processo.status_processo_id}
                    />
                </div>

                {processo.tipo_licenca?.has_apia_atia && (
                    <div className="col-md-4">
                        {processo &&
                        processo.analise_previa &&
                        processo.tipo_licenca ? (
                            <ApiaResumo
                                analise_id={processo.analise_previa?.id}
                                processo_id={processo.id}
                                tipo_licenca_id={processo.tipo_licenca.id}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                )}

                {processo.tipo_licenca?.has_apia_atia &&
                    processo.analise_processo?.concluida && (
                        <div className="col-md-4">
                            <AnaliseImpactoAmbiental />
                        </div>
                    )}

                <div className="col-md-4">
                    <MovimentacaoProcesso processo_id={processo_id} />
                </div>
                <div className="col-md-4">
                    <CheckList processo_id={processo_id} />
                </div>

                {processo?.tipo_licenca?.has_apia_atia && (
                    <div className="col-md-4">
                        <IndicadoresAmbientais
                            actions={false}
                            indicadores_ambientais={indicadores_ambientais}
                            processo_id={processo_id}
                        />
                    </div>
                )}

                <div className="col-md-4">
                    <MensagensProcesso processo={processo} />
                </div>
                <div className="col-md-4">
                    <NotaTecAmbiental
                        onlyView
                        processo_id={processo_id}
                        setNotaTecIsOk={setNotaTecIsOk}
                    />
                </div>
                <div className="col-md-4">
                    <AgendaVisionariaTecAmb
                        onlyView
                        processo_id={processo_id}
                        setAgendaVistoriaIsOk={setAgendaVistoriaIsOk}
                    />
                </div>

                <div className="col-md-4">
                    <RelatorioVistoria
                        onlyView
                        setRelatorioIsOk={setRelatorioIsOk}
                        vistorias={processo.vistorias}
                    />
                </div>
            </div>
        </BasicContent>
    );
};

export default Show;
