import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Loading } from '~/components';
import { apiWithToken } from '~/services/api';
import Swal from 'sweetalert2';

export default function ProcessPrint({ processo_id }) {
  const token = localStorage.getItem('@token');
  const api = apiWithToken(token);
  const [show, setShow] = useState(false);
  const [documentUrl, setDocumentUrl] = useState(null);

  async function getDocument() {
    try {
      const response = await api.get(`/processos/${processo_id}/print`);
      const byteCharacters = atob(response.data.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create object URL
      const url = URL.createObjectURL(blob);
      setDocumentUrl(url);
      setShow(true);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Erro ao gerar o PDF do Processo'
      });
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            Baixar processo
          </h3>
        </div>
        <div className="card-body">
          <button
            className="btn btn-primary btn-block"
            onClick={() => getDocument()}
          >
            <i className="fa fa-file-pdf" />&nbsp;&nbsp;
            Baixar processo
          </button>
        </div>
      </div>
      <Modal
        animation
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="modal-xl"
        onHide={() => setShow(false)}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title>Visualização do Processo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {document ? (
            <iframe
              src={documentUrl}
              style={{ width: '100%', height: '70vh' }}
              title={"Visualização do Processo"}
            />
          ) : (
            <Loading />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => window.open(documentUrl, '_blank')}
          >
            <i className="fa fa-download" /> Download
          </button>
          <button
            className="btn btn-default"
            onClick={() => setShow(false)}
            type="button"
          >
            <i className="fa fa-window-close" /> Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
