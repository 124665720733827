import React, { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import FileInputCsv from '../../../components/FileInputCsv';
import Swal from 'sweetalert2';
import { Loading, GerateLinkToCsv } from '~/components';
import { import_data } from '../../../services/Atividade';
import { useDispatch } from 'react-redux';
import { loadAtividades } from '~/store/modules/atividade/action';

function ImportAtividade() {
    const [loading, setLoading] = useState(false);
    const [dataError, setDataError] = useState();
    const { entidade_id } = useParams();
    const [arquivo, setArquivo] = useState({});
    const dispatch = useDispatch();
    let history = useHistory();

    const headers = [
        { label: 'nome', key: 'nome' },
        { label: 'nome_local', key: 'nome_local' },
        { label: 'codigo', key: 'codigo' },
        { label: 'potencial_poluidor', key: 'potencial_poluidor' },
    ];

    const data = [
        {
            nome: 'Nome da Atividade na Receita Federal',
            nome_local: 'Nome Local da Atividade',
            codigo: 'código de exemplo',
            potencial_poluidor: 'Médio',
        },
    ];

    const handleImportData = async () => {
        try {
            setLoading(true);
            const { status } = await import_data(entidade_id, arquivo);

            setLoading(false);
            if (status === 200) {
                await dispatch(
                    dispatch(
                        loadAtividades({
                            where: `entidade_id:${entidade_id}`,
                            with: 'potencial_poluidor',
                        })
                    )
                );
                await Swal.fire(
                    'Sucesso',
                    'Registro salvo com sucesso!',
                    'success'
                );
                history.goBack();
            }
        } catch (error) {
            setDataError('Erro inesperado aconteceu, verifique seus dados.');
            if (error.response.status === 422) {
                let server_errors = [];
                server_errors = error.response.data;

                for (let [, value] of Object.entries(server_errors[0])) {
                    Swal.fire('Algo deu errado!', `${value}`, 'error');
                }
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setDataError(null);
    }, [arquivo]);

    const handleClose = () => {
        history.goBack();
    };

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-md"
            onHide={handleClose}
            show
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Importar dados de Atividades
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <Alert variant="info">
                            Os arquivos devem ser obrigatoriamente em{' '}
                            <strong>CSV</strong> como exemplo você pode usar
                            clicando{' '}
                            <GerateLinkToCsv
                                data_fields={data}
                                data_headers={headers}
                                import_table_name="atividades"
                            >
                                aqui.
                            </GerateLinkToCsv>
                        </Alert>

                        <FileInputCsv
                            data={arquivo}
                            dataError={dataError}
                            name="atividades_import"
                            setData={setArquivo}
                        />
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-default"
                    disabled={loading}
                    onClick={handleClose}
                >
                    <i className="fa fa-window-close" /> Cancelar
                </button>
                <button
                    className="btn btn-success"
                    disabled={loading}
                    onClick={handleImportData}
                    type="button"
                >
                    {' '}
                    <i className="fa fa-save" /> Salvar
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default ImportAtividade;
