import React, { useCallback, useEffect, useState } from 'react';
import {
    useParams,
    Switch,
    Route,
    useLocation,
    Link,
    useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { Loading } from '~/components';
import Swal from 'sweetalert2';
import { showQuestaoAnalise } from '~/store/modules/questao_analise/action';
import {
    loadAlternativaQuestaoAnalises,
    removerAlternativaQuestaoAnalise,
    ativarAlternativaQuestaoAnalise,
} from '~/store/modules/alternativa_questao_analise/action';

import CreateAlternativaQuestaoAnalise from '../../AlternativaQuestaoAnalise/Create';
import EditAlternativaQuestaoAnalise from '../../AlternativaQuestaoAnalise/Edit';
import ImportAlternativaQuestaoAnalise from '../../AlternativaQuestaoAnalise/ImportAlternativaQuestaoAnalise';

import { BasicContent } from '../components';
import { SubMenuList } from '../components/SubMenu';

const Show = () => {
    let location = useLocation();
    let modal = location.state && location.state.modal;
    let history = useHistory();

    const dispatch = useDispatch();
    const { questao_analise_id } = useParams();
    const [questaoAnaliseShow, setQuestaoAnaliseShow] = useState({});
    const { questao_analise } = useSelector(
        ({ QuestaoAnaliseReducer }) => QuestaoAnaliseReducer
    );
    const { alternativa_questao_analises } = useSelector(
        ({ AlternativaQuestaoAnaliseReducer }) =>
            AlternativaQuestaoAnaliseReducer
    );

    useEffect(() => {
        dispatch(
            showQuestaoAnalise(questao_analise_id, {
                with: 'tipo_meio_analise.meio_analise.tipo_licenca,tipo_meio_analise.meio_analise.tipo_licenca.atividade,tipo_meio_analise.meio_analise.tipo_licenca.atividade.entidade',
                with_trashed: true,
            })
        );

        dispatch(
            loadAlternativaQuestaoAnalises({
                where: `questao_analise_id:${questao_analise_id}`,
                with_trashed: true,
            })
        );
    }, [dispatch, questao_analise_id]);

    useEffect(() => {
        if (questao_analise.id === parseInt(questao_analise_id)) {
            setQuestaoAnaliseShow(questao_analise);
        }
    }, [questao_analise, questao_analise_id]);

    const handleRemoveAlternativaQuestao = useCallback(
        (id) => {
            Swal.fire({
                title: 'Desativar?',
                text: 'Deseja desativar esse item?!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.value) {
                    try {
                        await dispatch(removerAlternativaQuestaoAnalise(id));
                        await dispatch(
                            loadAlternativaQuestaoAnalises({
                                where: `questao_analise_id:${questao_analise_id}`,
                                with_trashed: true,
                            })
                        );
                        await Swal.fire(
                            'Sucesso',
                            'Desativado com sucesso!',
                            'success'
                        );
                        await history.go();
                    } catch (error) {
                        await Swal.fire('Erro', 'Erro ao Desativar', 'error');
                    }
                }
            });
        },
        [dispatch, questao_analise_id, history]
    );

    const handleActivateAlternativaQuestao = useCallback(
        (id) => {
            Swal.fire({
                title: 'Ativar?',
                text: 'Deseja ativar esse item?!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.value) {
                    try {
                        await dispatch(ativarAlternativaQuestaoAnalise(id));
                        await dispatch(
                            loadAlternativaQuestaoAnalises({
                                where: `questao_analise_id:${questao_analise_id}`,
                                with_trashed: true,
                            })
                        );
                        await Swal.fire(
                            'Sucesso',
                            'Ativado com sucesso!',
                            'success'
                        );
                        await history.go();
                    } catch (error) {
                        await Swal.fire('Erro', 'Erro ao ativar', 'error');
                    }
                }
            });
        },
        [dispatch, questao_analise_id, history]
    );

    const breadcrumb = [
        {
            label: 'Entidade',
            to: `/entidade/${questaoAnaliseShow?.tipo_meio_analise?.meio_analise?.tipo_licenca?.atividade?.entidade_id}/detalhe`,
            active: true,
        },
        {
            label: 'Atividade',
            to: `/atividade/${questaoAnaliseShow?.tipo_meio_analise?.meio_analise?.tipo_licenca?.atividade_id}/detalhe`,
            active: true,
        },
        {
            label: 'Tipo Licença',
            to: `/tipo_licenca/${questaoAnaliseShow?.tipo_meio_analise?.meio_analise?.tipo_licenca_id}/detalhe`,
            active: true,
        },
        {
            label: 'Meio de analise',
            to: `/meio_analise/${questaoAnaliseShow?.tipo_meio_analise?.meio_analise_id}/detalhe`,
            active: true,
        },
        {
            label: 'Impacto ambientais',
            to: `/tipo_meio_analise/${questaoAnaliseShow?.tipo_meio_analise_id}/detalhe`,
            active: false,
        },
        {
            label: 'Questão Analise',
            to: `/questao_analise/${questaoAnaliseShow.id}/detalhe`,
            active: true,
        },
        {
            label: 'Detalhes',
            to: `/questao_analise/${questaoAnaliseShow.id}/detalhe`,
            active: true,
        },
    ];

    return (
        <>
            {modal && (
                <Switch>
                    <Route
                        children={<CreateAlternativaQuestaoAnalise />}
                        path={
                            '/questao_analise/:questao_analise_id/alternativa/novo'
                        }
                    />
                    <Route
                        children={<EditAlternativaQuestaoAnalise />}
                        path={
                            '/questao_analise/:questao_analise_id/alternativa/:id_alternativa/editar'
                        }
                    />
                    <Route
                        children={<ImportAlternativaQuestaoAnalise />}
                        path={
                            '/questao_analise/:questao_analise_id/AlternativaQuestaoAnalise/import_data'
                        }
                    />
                </Switch>
            )}
            <BasicContent breadcrumb={breadcrumb}>
                <div className="card">
                    <div className="card-header">
                        <h2 className="card-title">Detalhes</h2>
                        <div className="card-tools">
                            <Link
                                className="btn btn-default"
                                to={`/tipo_meio_analise/${questaoAnaliseShow?.tipo_meio_analise_id}/detalhe`}
                            >
                                <FaArrowAltCircleLeft /> Voltar
                            </Link>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            {questaoAnaliseShow.id ===
                            parseInt(questao_analise_id) ? (
                                <table className="table table-hover table-striped">
                                    <tbody>
                                        <tr>
                                            <th>Entidade</th>
                                            <td>
                                                <Link
                                                    to={`/entidade/${questaoAnaliseShow?.tipo_meio_analise?.meio_analise?.tipo_licenca?.atividade?.entidade_id}/detalhe`}
                                                >
                                                    {
                                                        questaoAnaliseShow
                                                            ?.tipo_meio_analise
                                                            ?.meio_analise
                                                            ?.tipo_licenca
                                                            ?.atividade
                                                            ?.entidade?.nome
                                                    }
                                                </Link>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>
                                                Nome da Atividade na Receita
                                                Federal
                                            </th>
                                            <td>
                                                <Link
                                                    to={`/atividade/${questaoAnaliseShow?.tipo_meio_analise?.meio_analise?.tipo_licenca?.atividade_id}/detalhe`}
                                                >
                                                    {
                                                        questaoAnaliseShow
                                                            ?.tipo_meio_analise
                                                            ?.meio_analise
                                                            ?.tipo_licenca
                                                            ?.atividade?.nome
                                                    }
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Nome Local da Atividade</th>
                                            <td>
                                                <Link
                                                    to={`/atividade/${questaoAnaliseShow?.tipo_meio_analise?.meio_analise?.tipo_licenca?.atividade_id}/detalhe`}
                                                >
                                                    {
                                                        questaoAnaliseShow
                                                            ?.tipo_meio_analise
                                                            ?.meio_analise
                                                            ?.tipo_licenca
                                                            ?.atividade
                                                            ?.nome_local
                                                    }
                                                </Link>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Tipo Licença</th>
                                            <td>
                                                <Link
                                                    to={`/tipo_licenca/${questaoAnaliseShow?.tipo_meio_analise?.meio_analise?.tipo_licenca_id}/detalhe`}
                                                >
                                                    {
                                                        questaoAnaliseShow
                                                            ?.tipo_meio_analise
                                                            ?.meio_analise
                                                            ?.tipo_licenca?.nome
                                                    }
                                                </Link>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Meio de analise</th>
                                            <td>
                                                <Link
                                                    to={`/meio_analise/${questaoAnaliseShow?.tipo_meio_analise?.meio_analise_id}/detalhe`}
                                                >
                                                    {
                                                        questaoAnaliseShow
                                                            ?.tipo_meio_analise
                                                            ?.meio_analise?.nome
                                                    }
                                                </Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Impacto Ambiental</th>
                                            <td>
                                                <Link
                                                    to={`/tipo_meio_analise/${questaoAnaliseShow?.tipo_meio_analise_id}/detalhe`}
                                                >
                                                    {
                                                        questaoAnaliseShow
                                                            ?.tipo_meio_analise
                                                            ?.nome
                                                    }
                                                </Link>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>id</th>
                                            <td>{questaoAnaliseShow.id}</td>
                                        </tr>

                                        <tr>
                                            <th>Enunciado</th>
                                            <td>{questaoAnaliseShow.nome}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    </div>
                </div>
                {alternativa_questao_analises.data ? (
                    <>
                        <SubMenuList
                            action={loadAlternativaQuestaoAnalises}
                            createPath={
                                '/questao_analise/:questao_analise_id/alternativa/novo'
                            }
                            createUrl={`/questao_analise/${questao_analise_id}/alternativa/novo`}
                            displayName={'tipo_meio_analise_id'}
                            edit
                            handleActivate={handleActivateAlternativaQuestao}
                            handleRemove={handleRemoveAlternativaQuestao}
                            id={questao_analise_id}
                            labelsCol={[
                                'Id',
                                'Nome',
                                'Valor Mínimo',
                                'Valor Máximo',
                            ]}
                            title={'Alternativas'}
                            values={alternativa_questao_analises}
                            valuesCol={['id', 'nome', 'min_valor', 'max_valor']}
                            importDataUrl={`/questao_analise/${questao_analise_id}/AlternativaQuestaoAnalise/import_data`}
                        />
                    </>
                ) : (
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">Alternativas</h2>
                        </div>
                        <div className="card-body">
                            <Loading />
                        </div>
                    </div>
                )}
            </BasicContent>
        </>
    );
};

export default Show;
