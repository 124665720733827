import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormEdit } from '../components';
import Swal from 'sweetalert2';
import schema from './schema';
import {
    loadAtividades,
    updateAtividade,
    showAtividade,
} from '~/store/modules/atividade/action';
import { loadPotencialPoluidores } from '~/store/modules/potencial_poluidor/action';
import { Loading } from '~/components';

const Edit = ({ entidade_id }) => {
    let history = useHistory();
    const [atividade_edit, setAtividadeEdit] = useState(null);
    const [saved, setSaved] = useState(false);
    const { atividade_id } = useParams();
    const [errorsValidate, setErrorsValidate] = useState([]);
    const dispatch = useDispatch();
    const { status_response, errors, atividade } = useSelector(
        ({ AtividadeReducer }) => AtividadeReducer
    );

    useEffect(() => {
        dispatch(loadPotencialPoluidores());
        dispatch(
            showAtividade(atividade_id, {
                with: 'potencial_poluidor',
            })
        );
    }, [dispatch, atividade_id]);

    useEffect(() => {
        // console.log('errors Edit atividade: ',errors);
        if (errors.status && errors.status === 422) {
            let server_errors = {};
            const response_errors = errors.data.errors;
            for (let name of Object.keys(response_errors)) {
                Object.assign(server_errors, {
                    [name]: response_errors[name][0],
                });
            }
            setErrorsValidate(server_errors);
        }
        if (errors.update) {
            setSaved(errors.update);
        }
    }, [errors]);

    useEffect(() => {
        // console.log('Sucesso Edit atividade: ',status_response);
        // if (atividade !== {}) {
        if (Object.keys(atividade).length > 0) {
            setAtividadeEdit({
                nome: atividade.nome,
                nome_local: atividade.nome_local,
                codigo: atividade.codigo,
                potencial_poluidor_id: atividade.potencial_poluidor_id,
            });
        }
    }, [atividade]);

    useEffect(() => {
        // console.log('Sucesso Edit atividade: ',status_response);
        // console.log('Sucesso Edit atividade: ',status_response);
        if (saved && status_response === 200) {
            Swal.fire(
                'Sucesso',
                'Registro editado com sucesso!',
                'success'
            ).then(() => {
                history.goBack();
                dispatch(
                    loadAtividades({
                        where: `entidade_id:${entidade_id}`,
                        with: 'potencial_poluidor',
                    })
                );
                // dispatch(getAtividade({data:{data:{}}}));
            });
        }
    }, [status_response, history, dispatch, saved, entidade_id]);

    const handleClose = () => {
        history.goBack();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorsValidate([]);
        // console.log('atividade update: ',atividade_edit);
        if (!schema.isValidSync(atividade_edit)) {
            let yup_errors = {};
            schema
                .validate(atividade_edit, { abortEarly: false })
                .catch((err) => {
                    for (let error of err.inner) {
                        const { path, message } = error;
                        Object.assign(yup_errors, { [path]: message });
                    }
                    setErrorsValidate(yup_errors);
                });
        } else {
            const ativi = atividade_edit;
            ativi.potencial_poluidor_id = parseInt(ativi.potencial_poluidor_id);
            ativi.entidade_id = parseInt(entidade_id);
            await dispatch(updateAtividade(ativi, atividade_id));
        }
    };

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-xl"
            onHide={handleClose}
            show
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Atividade
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card">
                    <div className="card-header">
                        <h2 className="card-title">Editar</h2>
                    </div>
                    <div className="card-body">
                        {atividade_edit ? (
                            <FormEdit
                                atividade={atividade_edit}
                                errors={errorsValidate}
                                handleSubmit={handleSubmit}
                                schema={schema}
                                setAtividade={setAtividadeEdit}
                            />
                        ) : (
                            <Loading />
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-default" onClick={handleClose}>
                    <i className="fa fa-window-close" /> Cancelar
                </button>
                <button
                    className="btn btn-success"
                    form="form_atividade"
                    type="submint"
                >
                    {' '}
                    <i className="fa fa-save" /> Editar
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default Edit;
