import React, { useCallback, useEffect, useState } from 'react';
import {
    Link,
    useParams,
    Switch,
    Route,
    useLocation,
    useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowAltCircleLeft, FaEdit } from 'react-icons/fa';
import { Loading } from '~/components';
import Swal from 'sweetalert2';
import { BasicContent } from '../components';
import { SubMenuList } from '../components/SubMenu';
import {
    // makeFinancialAccount,
    showEntidade,
} from '~/store/modules/entidade/action';
import { loadAtividades } from '~/store/modules/atividade/action';
import {
    loadConfigAutorizacoes,
    removerConfigAutorizacao,
    ativarConfigAutorizacao,
} from '~/store/modules/config_autorizacao/action';
import {
    loadMotoristas,
    removerMotorista,
} from '~/store/modules/motorista/action';
import { loadVeiculos, removerVeiculo } from '~/store/modules/veiculo/action';
import {
    loadObservacoes,
    removerObservacao,
} from '~/store/modules/observacao/action';
import {
    loadCondicionantes,
    removerCondicionante,
} from '~/store/modules/condicionante/action';
import Create from '../../Atividade/Create';
import Edit from '../../Atividade/Edit';
import AtividadeImport from '../../Atividade/ImportAtividade';
import EditMotorista from '../../Motorista/Edit';
import CreateMotorista from '../../Motorista/Create';
import CreateVeiculo from '../../Veiculo/Create';
import EditVeiculo from '../../Veiculo/Edit';
import CreateCondicionante from '../../Condicionante/Create';
import EditCondicionante from '../../Condicionante/Edit';
import CreateObservacao from '../../Observacao/Create';
import EditObservacao from '../../Observacao/Edit';
import ConfigAutorizacaoCreate from '../../ConfigAutorizacao/Create';
import ConfigAutorizacaoEdit from '../../ConfigAutorizacao/Edit';
import ResetFinancialPassword from '../ResetFinancialPassword';
import RegisterFinancialAccount from '../RegisterFinancialAccount';

const Show = () => {
    let location = useLocation();
    let modal = location.state && location.state.modal;
    const dispatch = useDispatch();
    const { entidade_id } = useParams();
    const [entidadeShow, setEntidadeShow] = useState({});
    const [useTuxtuFAccount, setUseTuxtuFAccount] = useState(false);
    // const { user_auth } = useSelector(({ LoginReducer }) => LoginReducer);
    const { entidade } = useSelector(({ EntidadeReducer }) => EntidadeReducer);
    const { motoristas } = useSelector(
        ({ MotoristaReducer }) => MotoristaReducer
    );
    const { veiculos } = useSelector(({ VeiculoReducer }) => VeiculoReducer);
    const { atividades } = useSelector(
        ({ AtividadeReducer }) => AtividadeReducer
    );
    const { condicionantes } = useSelector(
        ({ CondicionanteReducer }) => CondicionanteReducer
    );
    const { observacoes } = useSelector(
        ({ ObservacaoReducer }) => ObservacaoReducer
    );
    const { config_autorizacoes } = useSelector(
        ({ ConfigAutorizacaoReducer }) => ConfigAutorizacaoReducer
    );
    const history = useHistory();

    useEffect(() => {
        const use_tuxtu_faccount = process.env.REACT_APP_USE_TUXTU_FACCOUNT;

        if (use_tuxtu_faccount === 'true' || use_tuxtu_faccount === 'TRUE') {
            setUseTuxtuFAccount(true);
        }

        if (use_tuxtu_faccount === 'false' || use_tuxtu_faccount === 'FALSE') {
            setUseTuxtuFAccount(false);
        }
    }, []);

    useEffect(() => {
        dispatch(
            loadCondicionantes({
                where: `entidade_id:${entidade_id}`,
            })
        );
        dispatch(
            showEntidade(entidade_id, {
                with: 'estado,municipio',
            })
        );
        dispatch(
            loadAtividades({
                where: `entidade_id:${entidade_id}`,
                with: 'potencial_poluidor',
                order_by: 'nome:asc',
            })
        );
        dispatch(
            loadMotoristas({
                where: `entidade_id:${entidade_id}`,
            })
        );
        dispatch(
            loadVeiculos({
                where: `entidade_id:${entidade_id}`,
            })
        );
        dispatch(
            loadObservacoes({
                where: `entidade_id:${entidade_id}`,
            })
        );
        dispatch(
            loadConfigAutorizacoes({
                where: `entidade_id: ${entidade_id}`,
                with_trashed: true,
            })
        );
    }, [dispatch, entidade_id]);

    useEffect(() => {
        if (entidade.id === parseInt(entidade_id)) {
            setEntidadeShow(entidade);
        }
    }, [entidade, entidade_id]);

    const breadcrumb = {
        label: 'Detalhes',
        to: `entidade/${entidade_id}/detalhe`,
        active: false,
    };

    const handleRemoverObservacao = useCallback(
        async (id) => {
            Swal.fire({
                title: 'Remover?',
                text: 'Deseja Remover esse item?!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.value) {
                    try {
                        await dispatch(removerObservacao(id));
                        await Swal.fire(
                            'Sucesso',
                            'Removido com sucesso!',
                            'success'
                        );
                        await dispatch(
                            loadObservacoes({
                                where: `entidade_id:${entidade_id}`,
                            })
                        );
                    } catch (error) {
                        await Swal.fire('Erro', 'Erro ao Remover', 'error');
                    }
                }
            });
        },
        [dispatch, entidade_id]
    );

    const handleRemoverMotorista = useCallback(
        async (id) => {
            Swal.fire({
                title: 'Remover?',
                text: 'Deseja Remover esse item?!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.value) {
                    try {
                        await dispatch(removerMotorista(id));
                        await Swal.fire(
                            'Sucesso',
                            'Removido com sucesso!',
                            'success'
                        );
                        await dispatch(
                            loadMotoristas({
                                where: `entidade_id:${entidade_id}`,
                            })
                        );
                    } catch (error) {
                        await Swal.fire('Erro', 'Erro ao Remover', 'error');
                    }
                }
            });
        },
        [dispatch, entidade_id]
    );

    const handleRemoverVeiculo = useCallback(
        async (id) => {
            Swal.fire({
                title: 'Remover?',
                text: 'Deseja Remover esse item?!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.value) {
                    try {
                        await dispatch(removerVeiculo(id));
                        await Swal.fire(
                            'Sucesso',
                            'Removido com sucesso!',
                            'success'
                        );
                        await dispatch(
                            loadVeiculos({
                                where: `entidade_id:${entidade_id}`,
                            })
                        );
                    } catch (error) {
                        await Swal.fire('Erro', 'Erro ao Remover', 'error');
                    }
                }
            });
        },
        [dispatch, entidade_id]
    );

    const handleRemoverCondicionante = useCallback(
        async (id) => {
            Swal.fire({
                title: 'Remover?',
                text: 'Deseja Remover esse item?!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.value) {
                    try {
                        await dispatch(removerCondicionante(id));
                        await Swal.fire(
                            'Sucesso',
                            'Removido com sucesso!',
                            'success'
                        );
                        await dispatch(
                            loadCondicionantes({
                                where: `entidade_id:${entidade_id}`,
                            })
                        );
                    } catch (error) {
                        await Swal.fire('Erro', 'Erro ao Remover', 'error');
                    }
                }
            });
        },
        [dispatch, entidade_id]
    );

    const handleRemoverConfigAutorizacao = useCallback(
        async (id) => {
            Swal.fire({
                title: 'Remover?',
                text: 'Deseja Remover esse item?!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.value) {
                    try {
                        await dispatch(removerConfigAutorizacao(id));
                        await Swal.fire(
                            'Sucesso',
                            'Removido com sucesso!',
                            'success'
                        );
                        await dispatch(
                            loadConfigAutorizacoes({
                                where: `entidade_id:${entidade_id}`,
                                with_trashed: true,
                            })
                        );
                    } catch (error) {
                        await Swal.fire('Erro', 'Erro ao Remover', 'error');
                    }
                }
            });
        },
        [dispatch, entidade_id]
    );

    const handleActivateConfigAutorizacao = useCallback(
        (id) => {
            Swal.fire({
                title: 'Ativar?',
                text: 'Deseja ativar esse item?!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim!',
                cancelButtonText: 'Não',
            }).then(async (result) => {
                if (result.value) {
                    try {
                        await dispatch(ativarConfigAutorizacao(id));
                        await dispatch(
                            loadConfigAutorizacoes({
                                where: `entidade_id:${entidade_id}`,
                                with_trashed: true,
                            })
                        );
                        await Swal.fire(
                            'Sucesso',
                            'Ativado com sucesso!',
                            'success'
                        );
                        await history.go();
                    } catch (error) {
                        await Swal.fire('Erro', 'Erro ao ativar', 'error');
                    }
                }
            });
        },
        [dispatch, entidade_id, history]
    );

    // const handleMakeFinancialAccount = useCallback(async (id) => {
    //   Swal.fire({
    //     title: 'Habilitar Conta?',
    //     html: `Crie uma senha para sua conta de integração BIPP<br/><input name="financial_password" onhange="${(event) => setFinancialPassword(event.target.value)}"/>`,
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Sim!',
    //     cancelButtonText: 'Não'
    //   }).then(async (result) => {
    //     if (result.value) {
    //       try {
    //         await dispatch(makeFinancialAccount(id));
    //         await Swal.fire(
    //           'Sucesso',
    //           'Conta habilitada!',
    //           'success'
    //         );
    //         await dispatch(showEntidade(id, {
    //           with:'estado,municipio'
    //         }));
    //       } catch (error) {
    //         await Swal.fire(
    //           'Erro',
    //           'A conta não pôde ser habilitada por falta de informação',
    //           'error'
    //         );
    //       }
    //     }
    //   })
    // },[dispatch]);

    return (
        <>
            {modal && (
                <Switch>
                    {!useTuxtuFAccount && (
                        <Route
                            children={
                                <RegisterFinancialAccount
                                    entidade={entidadeShow}
                                />
                            }
                            exact
                            path="/entidade/:entidade_id/detalhe/registrar-conta-financeira"
                        />
                    )}

                    {!useTuxtuFAccount && (
                        <Route
                            children={
                                <ResetFinancialPassword
                                    entidade={entidadeShow}
                                />
                            }
                            exact
                            path="/entidade/:entidade_id/detalhe/mudar-senha-conta-financeira"
                        />
                    )}

                    <Route
                        children={<Create entidade_id={entidade_id} />}
                        exact
                        path="/entidade/:entidade_id/atividade/novo"
                    />
                    <Route
                        children={<Edit entidade_id={entidade_id} />}
                        exact
                        path="/entidade/:entidade_id/atividade/editar/:atividade_id"
                    />
                    <Route
                        children={<AtividadeImport entidade_id={entidade_id} />}
                        exact
                        path="/entidade/:entidade_id/atividade/import_data"
                    />
                    <Route
                        children={<CreateMotorista entidade_id={entidade_id} />}
                        exact
                        path="/entidade/:entidade_id/motorista/novo"
                    />
                    <Route
                        children={<EditMotorista entidade_id={entidade_id} />}
                        exact
                        path="/entidade/:entidade_id/motorista/editar/:motorista_id"
                    />
                    <Route
                        children={<CreateVeiculo entidade_id={entidade_id} />}
                        exact
                        path="/entidade/:entidade_id/veiculo/novo"
                    />
                    <Route
                        children={<EditVeiculo entidade_id={entidade_id} />}
                        exact
                        path="/entidade/:entidade_id/veiculo/editar/:veiculo_id"
                    />
                    <Route
                        children={
                            <CreateCondicionante entidade_id={entidade_id} />
                        }
                        exact
                        path="/entidade/:entidade_id/condicionante/novo"
                    />
                    <Route
                        children={
                            <EditCondicionante entidade_id={entidade_id} />
                        }
                        exact
                        path="/entidade/:entidade_id/condicionante/editar/:condicionante_id"
                    />
                    <Route
                        children={
                            <CreateObservacao entidade_id={entidade_id} />
                        }
                        exact
                        path="/entidade/:entidade_id/observacao/novo"
                    />
                    <Route
                        children={<EditObservacao entidade_id={entidade_id} />}
                        exact
                        path="/entidade/:entidade_id/observacao/editar/:observacao_id"
                    />
                    <Route
                        children={
                            <ConfigAutorizacaoCreate
                                entidade_id={entidade_id}
                            />
                        }
                        exact
                        path="/entidade/:entidade_id/usuario_autorizacao/novo"
                    />
                    <Route
                        children={
                            <ConfigAutorizacaoEdit entidade_id={entidade_id} />
                        }
                        exact
                        path="/entidade/:entidade_id/usuario_autorizacao/editar/:config_autorizacao_id"
                    />
                    {/* <Route
            children={<EditCondicionante entidade_id={entidade_id}/>}
            exact
            path="/entidade/:entidade_id/condicionante/editar/:condicionante_id"
          /> */}
                </Switch>
            )}
            <BasicContent breadcrumb={breadcrumb}>
                <div className="card">
                    <div className="card-header">
                        <h2 className="card-title">Detalhes</h2>
                        <div className="card-tools">
                            <div className="btn-group">
                                <Link
                                    className="btn btn-default"
                                    to="/entidade"
                                >
                                    <FaArrowAltCircleLeft /> Voltar
                                </Link>
                                <Link
                                    className="btn btn-default"
                                    to={`/entidade/${entidade_id}/edit`}
                                >
                                    <FaEdit /> Editar
                                </Link>
                                {/* {!useTuxtuFAccount &&
                  !entidadeShow.pg_api_key &&
                  user_auth.user.tipo_user_id === 1 && (
                    <Link
                      className="btn btn-success"
                      style={{ display: 'flex', alignItems: 'center' }}
                      to={{
                        state: { modal: true },
                        pathname: `/entidade/${entidade_id}/detalhe/registrar-conta-financeira`,
                      }}
                    >
                      <FaEdit />
                      &nbsp;Registrar Conta Financeira
                    </Link>
                  )}
                {!useTuxtuFAccount &&
                  entidadeShow.pg_api_key &&
                  user_auth.user.tipo_user_id === 1 && (
                    <Link
                      className="btn btn-success"
                      style={{ display: 'flex', alignItems: 'center' }}
                      to={{
                        state: { modal: true },
                        pathname: `/entidade/${entidade_id}/detalhe/mudar-senha-conta-financeira`,
                      }}
                    >
                      <FaEdit />
                      &nbsp;Mudar Senha da Conta Financeira
                    </Link>
                  )} */}
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            {entidadeShow.id === parseInt(entidade_id) ? (
                                <table className="table table-hover table-striped">
                                    <tbody>
                                        <tr>
                                            <th>id</th>
                                            <td>{entidade.id}</td>
                                        </tr>
                                        <tr>
                                            <th>Nome</th>
                                            <td>{entidade.nome}</td>
                                        </tr>
                                        <tr>
                                            <th>Estado</th>
                                            <td>{entidade?.estado?.nome}</td>
                                        </tr>
                                        <tr>
                                            <th>Governador</th>
                                            <td>
                                                {entidade.nome_governador
                                                    ? entidade.nome_governador
                                                    : 'Não foi informado'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Município</th>
                                            <td>{entidade?.municipio?.nome}</td>
                                        </tr>
                                        <tr>
                                            <th>Prefeito</th>
                                            <td>
                                                {entidade.nome_prefeito
                                                    ? entidade.nome_prefeito
                                                    : 'Não foi informado'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Gestor Ambiental</th>
                                            <td>
                                                {entidade.nome_gestor_ambiental
                                                    ? entidade.nome_gestor_ambiental
                                                    : 'Não foi informado'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Site da Secretaria</th>
                                            <td>
                                                {entidade.site_secretaria
                                                    ? entidade.site_secretaria
                                                    : 'Não foi informado'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Telefone da Secretaria</th>
                                            <td>
                                                {entidade.telefone_secretaria
                                                    ? entidade.telefone_secretaria
                                                    : 'Não foi informado'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Secretaria faz licenciamento
                                            </th>
                                            <td>
                                                {entidade.secretaria_faz_licenciamento
                                                    ? 'SIM'
                                                    : 'NÃO'}
                                            </td>
                                        </tr>
                                        {/* <tr>
                      <th>Legislação Federal</th>
                      <td>{entidade.legislacao_federal?entidade.legislacao_federal:'Não foi informado'}</td>
                    </tr>
                    <tr>
                      <th>Legislação Estadual</th>
                      <td>{entidade.legislacao_estadual?entidade.legislacao_estadual:'Não foi informado'}</td>
                    </tr>
                    <tr>
                      <th>Legislação Municipal</th>
                      <td>{entidade.legislacao_municipal?entidade.legislacao_municipal:'Não foi informado'}</td>
                    </tr> */}
                                        <tr>
                                            <th>Função do Gestor Ambiental</th>
                                            <td>
                                                {entidade.funcao_gestor_ambiental
                                                    ? entidade.funcao_gestor_ambiental
                                                    : 'Não foi informado'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Nome do Órgão Ambiental</th>
                                            <td>
                                                {entidade.nome_orgao_ambiental
                                                    ? entidade.nome_orgao_ambiental
                                                    : 'Não foi informado'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Secretaria tem conselho meio
                                                ambiente
                                            </th>
                                            <td>
                                                {entidade.secretaria_tem_conselho_meio_ambiente
                                                    ? 'SIM'
                                                    : 'NÃO'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Legislacao do conselho</th>
                                            <td>
                                                {entidade.legislacao_conselho
                                                    ? entidade.legislacao_conselho
                                                    : 'Não foi informado'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Conselho tem periodicidade de
                                                reunioes
                                            </th>
                                            <td>
                                                {entidade.conselho_tem_preiodicidade_de_reunioes
                                                    ? 'SIM'
                                                    : 'NÃO'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Secretaria possui fundo de
                                                ambiente
                                            </th>
                                            <td>
                                                {entidade.secretaria_possui_fundo_de_ambiente
                                                    ? 'SIM'
                                                    : 'NÃO'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Tem PF ou PJ realizando servicos
                                                ambientais
                                            </th>
                                            <td>
                                                {entidade.tem_pf_ou_pj_realizando_servicos_ambientais
                                                    ? 'SIM'
                                                    : 'NÃO'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Ativo</th>
                                            <td>
                                                {entidade.ativo ? 'SIM' : 'NÃO'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    </div>
                </div>
                {atividades.data ? (
                    <>
                        <SubMenuList
                            action={loadAtividades}
                            createPath={'/entidade/:entidade_id/atividade/novo'}
                            createUrl={`/entidade/${entidade_id}/atividade/novo`}
                            detalhe
                            displayName={'entidade_id'}
                            displayWith={'potencial_poluidor'}
                            editar
                            id={entidade_id}
                            importDataUrl={`/entidade/${entidade_id}/atividade/import_data`}
                            labelCol1={'Id'}
                            labelCol2={'Nome da Atividade na Receita Federal'}
                            labelCol3={'Nome Local da Atividade'}
                            labelCol4={'Potencial poluidor'}
                            path_detalhe={'/atividade/*/detalhe'}
                            path_editar={`/entidade/${entidade_id}/atividade/editar/`}
                            title={'Atividades'}
                            valueCol1={'id'}
                            valueCol2={'nome'}
                            valueCol3={'nome_local'}
                            valueCol4={'potencial_poluidor'}
                            values={atividades}
                            displayOrderBy={'nome:asc'}
                        />
                    </>
                ) : (
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">Tipos de licenças</h2>
                        </div>
                        <div className="card-body">
                            <Loading />
                        </div>
                    </div>
                )}
                {motoristas.data ? (
                    <>
                        <SubMenuList
                            action={loadMotoristas}
                            createPath={'/entidade/:entidade_id/motorista/novo'}
                            createUrl={`/entidade/${entidade_id}/motorista/novo`}
                            detalhe
                            displayName={'entidade_id'}
                            displayWith={'entidade'}
                            editar
                            handleRemove={handleRemoverMotorista}
                            id={entidade_id}
                            labelCol1={'Id'}
                            labelCol2={'Nome'}
                            path_detalhe={'/motorista/*/detalhe'}
                            path_editar={`/entidade/${entidade_id}/motorista/editar/`}
                            remover
                            title={'Motoristas'}
                            valueCol1={'id'}
                            valueCol2={'nome'}
                            values={motoristas}
                        />
                    </>
                ) : (
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">Motoristas</h2>
                        </div>
                        <div className="card-body">
                            <Loading />
                        </div>
                    </div>
                )}
                {observacoes.data ? (
                    <>
                        <SubMenuList
                            action={loadObservacoes}
                            createPath={
                                '/entidade/:entidade_id/observacao/novo'
                            }
                            createUrl={`/entidade/${entidade_id}/observacao/novo`}
                            detalhe
                            displayName={'entidade_id'}
                            displayWith={'entidade'}
                            editar
                            handleRemove={handleRemoverObservacao}
                            id={entidade_id}
                            labelCol1={'Id'}
                            labelCol2={'Descrição'}
                            path_detalhe={'/observacao/*/detalhe'}
                            path_editar={`/entidade/${entidade_id}/observacao/editar/`}
                            remover
                            title={'Observação'}
                            valueCol1={'id'}
                            valueCol2={'descricao'}
                            values={observacoes}
                        />
                    </>
                ) : (
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">Motoristas</h2>
                        </div>
                        <div className="card-body">
                            <Loading />
                        </div>
                    </div>
                )}
                {condicionantes.data ? (
                    <>
                        <SubMenuList
                            action={loadCondicionantes}
                            createPath={
                                '/entidade/:entidade_id/condicionante/novo'
                            }
                            createUrl={`/entidade/${entidade_id}/condicionante/novo`}
                            displayName={'entidade_id'}
                            editar
                            handleRemove={handleRemoverCondicionante}
                            id={entidade_id}
                            labelCol2={'Nome'}
                            labelCol3={'Meio'}
                            path_editar={`/entidade/${entidade_id}/condicionante/editar/`}
                            remover
                            styleCol3={{ width: '100px' }}
                            title={'Condicionantes'}
                            valueCol2={'nome'}
                            valueCol3={'meio_analise'}
                            values={condicionantes}
                        />
                    </>
                ) : (
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">Condicionantes</h2>
                        </div>
                        <div className="card-body">
                            <Loading />
                        </div>
                    </div>
                )}
                {veiculos.data ? (
                    <>
                        <SubMenuList
                            action={loadVeiculos}
                            createPath={'/entidade/:entidade_id/veiculo/novo'}
                            createUrl={`/entidade/${entidade_id}/veiculo/novo`}
                            detalhe
                            displayName={'entidade_id'}
                            displayWith={'entidade'}
                            editar
                            handleRemove={handleRemoverVeiculo}
                            id={entidade_id}
                            labelCol1={'Id'}
                            labelCol2={'Modelo'}
                            labelCol3={'Placa'}
                            path_detalhe={'/veiculo/*/detalhe'}
                            path_editar={`/entidade/${entidade_id}/veiculo/editar/`}
                            remover
                            title={'Veiculos'}
                            valueCol1={'id'}
                            valueCol2={'modelo'}
                            valueCol3={'placa'}
                            values={veiculos}
                        />
                    </>
                ) : (
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">Veiculos</h2>
                        </div>
                        <div className="card-body">
                            <Loading />
                        </div>
                    </div>
                )}

                {config_autorizacoes.data ? (
                    <>
                        <SubMenuList
                            action={loadConfigAutorizacoes}
                            createPath={
                                '/entidade/:entidade_id/usuario_autorizacao/novo'
                            }
                            createUrl={`/entidade/${entidade_id}/usuario_autorizacao/novo`}
                            desativar
                            displayName={'entidade_id'}
                            editar
                            handleActivate={handleActivateConfigAutorizacao}
                            handleRemove={handleRemoverConfigAutorizacao}
                            id={entidade_id}
                            labelCol1={'Id'}
                            labelCol2={'Nível'}
                            labelCol3={'Nomeclatura'}
                            labelCol4={'Obrigatório'}
                            path_editar={`/entidade/${entidade_id}/usuario_autorizacao/editar/`}
                            title={'Configurações de Autorização'}
                            valueCol1={'id'}
                            valueCol2={'nivel_autorizacao'}
                            valueCol3={'nomeclatura'}
                            valueCol4={'obrigatoria'}
                            values={config_autorizacoes}
                        />
                    </>
                ) : (
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">
                                Configuração de Autorização
                            </h2>
                        </div>
                        <div className="card-body">
                            <Loading />
                        </div>
                    </div>
                )}

                {/* {entidadeShow.id === parseInt(entidade_id) ? (
          <SubMenuList entidade_id={entidade_id} />
        ) : (
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Menus</h2>
            </div>
            <div className="card-body">
              <Loading />
            </div>
          </div>
        )} */}
            </BasicContent>
        </>
    );
};

export default Show;
