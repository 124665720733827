import * as Yup from 'yup';

const schema = Yup.object().shape({
    nome: Yup.string().required('O campo "nome" é obrigatório.'),
    nome_local: Yup.string().required('O campo "Nome local" é obrigatório.'),
    codigo: Yup.string().required('O campo "código CNAE" é obrigatório.'),
    potencial_poluidor_id: Yup.number()
        .typeError('O campo "Pontencial poluidor" deve ser um número.')
        .required('O campo "Pontencial poluidor" é obrigatório.'),
});

export default schema;
